define("semente-web-app/models/aula", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  moment.updateLocale('en', {
    monthsShort: ["jan", "fev", "mar", "abr", "maio", "jun", "jul", "ago", "set", "out", "nov", "dez"],
    months: ["janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"]
  });

  var _default = _emberData.default.Model.extend({
    titulo: _emberData.default.attr(),
    descricao: _emberData.default.attr(),
    isEssencial: _emberData.default.attr(),
    hasContent: _emberData.default.attr(),
    idx: _emberData.default.attr(),
    dataInicioPrevista: _emberData.default.attr(),
    dataInicioPrevistaFormat: Ember.computed('dataInicioPrevista', function () {
      var dataformatada = moment(this.get('dataInicioPrevista'), 'DD/MM/YYYY hh:mm:ss').format('DD MMM');
      return dataformatada;
    }),
    dataFimPrevista: _emberData.default.attr(),
    dataFimPrevistaFormat: Ember.computed('dataFimPrevista', function () {
      var dataformatada = moment(this.get('dataFimPrevista'), 'DD/MM/YYYY hh:mm:ss').format('DD MMM');
      return dataformatada;
    }),
    mesFimPrevistoFormat: Ember.computed('dataFimPrevista', function () {
      var dataformatada = moment(this.get('dataFimPrevista'), 'DD/MM/YYYY hh:mm:ss').format('MMMM');
      return dataformatada;
    }),
    thumbnail: _emberData.default.attr(),
    plataformaConteudos: _emberData.default.hasMany('plataforma-conteudo', {
      async: true
    }),
    plataformaAno: _emberData.default.belongsTo('plataforma-ano', {
      async: true
    }),
    aplicacoes: _emberData.default.hasMany('aplicacao-plataforma-aula', {
      async: true
    }),
    unidade: _emberData.default.belongsTo('unidade', {
      async: true
    }),
    comps: _emberData.default.hasMany('comp', {
      async: true
    }),
    atividade: _emberData.default.belongsTo('atividade', {
      async: true
    }),
    tarefas: _emberData.default.hasMany('tarefa', {
      async: true
    }),
    avaliacoes: _emberData.default.hasMany('avaliacao', {
      async: true
    }),
    anotacoes: _emberData.default.hasMany('anotacao', {
      async: true
    }),
    calendarios: _emberData.default.hasMany('calendario', {
      async: true
    })
  });

  _exports.default = _default;
});