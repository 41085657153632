define("semente-web-app/routes/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "semente-web-app/config/environment"], function (_exports, _authenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    env: _environment.default.APP,
    store: Ember.inject.service(),
    session: Ember.inject.service('session'),
    rootURL: _environment.default.rootURL,
    envnmt: _environment.default.APP,
    goToStepTwo: false,
    userName: null,
    afterModel: function afterModel() {
      if (this.get('session.isAuthenticated')) {
        this.transitionTo('/webapp');
      }
    }
  });

  _exports.default = _default;
});