define("semente-web-app/models/acompanhamento-plataforma", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    acompanhamentoPessoasPlataforma: _emberData.default.hasMany('acompanhamento-pessoa-plataforma', {
      async: true
    }),
    acompanhamentoSistemasPlataforma: _emberData.default.hasMany('acompanhamento-sistema-plataforma', {
      async: true
    }),
    acompanhamentoAtividadesPlataforma: _emberData.default.hasMany('acompanhamento-atividade-instituicao', {
      async: true
    }),
    nrAlunos: _emberData.default.attr(),
    nrProfessores: _emberData.default.attr(),
    nrSecretarias: _emberData.default.attr(),
    nrMarketings: _emberData.default.attr(),
    nrCoordenadores: _emberData.default.attr(),
    nrGestores: _emberData.default.attr(),
    professoresFiltered: _emberData.default.attr(),
    alunosFiltered: _emberData.default.attr()
  });

  _exports.default = _default;
});