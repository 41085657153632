define("semente-web-app/models/quiz-comp", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    conteudo: _emberData.default.belongsTo('conteudo', {
      async: true
    }),
    competencia: _emberData.default.belongsTo('comp', {
      async: true
    }),
    questoesComp: _emberData.default.hasMany('questao-comp', {
      async: true
    })
  });

  _exports.default = _default;
});