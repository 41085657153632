define("semente-web-app/models/modulo", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    description: _emberData.default.attr(),
    completion: _emberData.default.attr(),
    habilitado: _emberData.default.attr(),
    coverImage: _emberData.default.attr(),
    dataInscricao: _emberData.default.attr(),
    idx: _emberData.default.attr(),
    autor: _emberData.default.attr(),
    videoId: _emberData.default.attr(),
    duracao: _emberData.default.attr(),
    atividades: _emberData.default.hasMany('atividade', {
      async: true
    }),
    turmas: _emberData.default.hasMany('turma', {
      async: true
    }),
    pessoas: _emberData.default.hasMany('pessoa', {
      async: true
    }),
    sistema: _emberData.default.belongsTo('sistema', {
      async: true
    }),
    acompanhamentosCursoInstituicao: _emberData.default.hasMany('acompanhamento-curso-instituicao', {
      async: true
    }),
    backgroundImage: Ember.computed('coverImage', function () {
      return new Ember.String.htmlSafe("background-image: url('" + this.get('coverImage') + "');");
    })
  });

  _exports.default = _default;
});