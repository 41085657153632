define("semente-web-app/models/pessoa-notification", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    pessoa: _emberData.default.belongsTo('pessoa', {
      async: true
    }),
    notification: _emberData.default.belongsTo('notification', {
      async: true
    }),
    viewed: _emberData.default.attr(),
    view: _emberData.default.attr()
  });

  _exports.default = _default;
});