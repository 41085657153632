define("semente-web-app/helpers/equalstr", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.equalstr = equalstr;

  function equalstr(params) {
    var _params = (0, _slicedToArray2.default)(params, 2),
        arg1 = _params[0],
        arg2 = _params[1];

    var resp = 0;
    if (arg1 == arg2) resp = 1;
    return resp;
  }

  var _default = Ember.Helper.helper(equalstr);

  _exports.default = _default;
});