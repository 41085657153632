define("semente-web-app/models/certificado", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    pessoa: _emberData.default.attr(),
    dataEmissao: _emberData.default.attr(),
    codigo: _emberData.default.attr(),
    plataformaAno: _emberData.default.attr(),
    segmento: _emberData.default.attr(),
    sistema: _emberData.default.attr(),
    horas: _emberData.default.attr()
  });

  _exports.default = _default;
});