define("semente-web-app/session-stores/application", ["exports", "ember-simple-auth/session-stores/cookie", "semente-web-app/config/environment"], function (_exports, _cookie, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var oneYear = 365 * 24 * 60 * 60;

  var _default = _cookie.default.extend({
    // set an explicit expiration time so session does not expire when window is closed
    cookieExpirationTime: oneYear,
    cookieDomain: ".".concat(_environment.default.APP.DOMAIN)
  });

  _exports.default = _default;
});