define("semente-web-app/controllers/autoregister/form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service('session'),
    escola: Ember.computed('model', function () {
      var escola = this.get('model').get('instituicao');
      return escola;
    }),
    // preventLoginPaste: Ember.run.later('afterRender', function() {
    //   setTimeout(() => {
    //     document.getElementById('emailUser').onpaste = function(){
    //       // Pega alerta
    //     const errorCompartiment = document.getElementById('login-error');
    //     // Pega animação do alerta
    //     const alertAnimation = errorCompartiment.dataset.animation;
    //     // Pega container da mensagem a ser escrita
    //     const msg = errorCompartiment.querySelector('[class*="__msg"]');
    //     // Mensagem
    //     let errorMsg = 'Por favor, digite'
    //     // Injeta mensagem de erro.
    //     msg.innerHTML = '<strong>' + errorMsg + '</strong>';
    //     // Confere se o elemento já está aparecendo
    //     if (!errorCompartiment.classList.contains('alert--is-show')) {
    //     // Adiciona duas classes: uma para o alerta aparecer e outra com a animação definida no html, por meio de data-SBRUBLES
    //       errorCompartiment.classList.add('alert--is-show', alertAnimation);
    //     }
    //       return false;
    //   }
    //   }, 1000);
    // }),
    pessoa: Ember.computed('model', function () {
      return this.get('store').createRecord('pessoa');
    }),
    emailFocus: Ember.computed(function () {
      var input = document.getElementById('emailUser');

      if (input) {
        input.focus();
      }
    }),
    // ValidaCPFValido(strCPF) {
    //   var Soma;
    //   var Resto;
    //   Soma = 0;
    //   strCPF = strCPF.replace(".", "");
    //   strCPF = strCPF.replace(".", "");
    //   strCPF = strCPF.replace("-", "");
    //   strCPF = strCPF.trim();
    //   if (strCPF == "00000000000") return false;
    //   for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    //   Resto = (Soma * 10) % 11;
    //   if ((Resto == 10) || (Resto == 11)) Resto = 0;
    //   if (Resto !== parseInt(strCPF.substring(9, 10))) return false;
    //   Soma = 0;
    //   for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    //   Resto = (Soma * 10) % 11;
    //   if ((Resto == 10) || (Resto == 11)) Resto = 0;
    //   if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
    //   return true;
    // },
    verifyPasswordLength: function verifyPasswordLength() {
      var p1 = document.getElementById('senha').value;
      var p2 = document.getElementById('senha2').value;
      var passAlert = document.getElementById('pass-error');
      var msg = passAlert.querySelector('[class*="__msg"]');

      if (p1.length < 6 || p2.length < 6) {
        var errorMsg = 'A senha precisa ter pelo menos 6 caracteres.';
        passAlert.classList.add('alert--is-show', 'fadeIn');
        msg.innerHTML = '<strong>' + errorMsg + '</strong>';
        return false;
      } else {
        passAlert.classList.remove('alert--is-show', 'fadeIn');
        return true;
      }
    },
    // verifyIdentificationFill() {
    //   let identInputs = document.querySelectorAll('.j-validate-ident-text');
    //   let fieldsetError = document.getElementById('error-fieldset-ident');
    //   let notFilled = 0;
    //   this.set('fieldset1Error', '');
    //   identInputs.forEach(i => {
    //     if (!i.disabled && i.value.length < 1) {
    //       i.classList.add('fieldset__field--presents-error');
    //       notFilled++
    //     } else {
    //       i.classList.remove('fieldset__field--presents-error');
    //     }
    //   })
    //   if (notFilled > 0) {
    //     fieldsetError.classList.add('fieldset__error--is-show');
    //     this.set('fieldset1Error', 'Há campos não preenchidos');
    //     return false;
    //   }
    //   fieldsetError.classList.remove('fieldset__error--is-show');
    //   return true;
    // },
    actions: {
      liveCheckEmail: function liveCheckEmail() {
        $('#login').on('keypress', function (event) {
          var regex = new RegExp("^[a-zA-Z0-9@.-_]+$");
          var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
          var errorMsg = 'Espaço e caracteres especiais não são permitidos';

          if (!regex.test(key)) {
            // Pega form container e tira classe de validado
            var inputContainer = document.getElementById('login').closest('.form-group__input-container');
            inputContainer.classList.remove('form-group__input-container--is-validated'); // Pega alerta

            var errorCompartiment = document.getElementById('login-error'); // Pega animação do alerta

            var alertAnimation = errorCompartiment.dataset.animation; // Pega container da mensagem a ser escrita

            var msg = errorCompartiment.querySelector('[class*="__msg"]');
            errorCompartiment.classList.remove('alert--is-show', alertAnimation); // Injeta mensagem de erro.

            msg.innerHTML = '<strong>' + errorMsg + '</strong>'; // Confere se o elemento já está aparecendo

            if (!errorCompartiment.classList.contains('alert--is-show')) {
              // Adiciona duas classes: uma para o alerta aparecer e outra com a animação definida no html, por meio de data-SBRUBLES
              errorCompartiment.classList.add('alert--is-show', alertAnimation);
            }

            event.preventDefault();
            return false;
          } else {
            var _errorCompartiment = document.getElementById('login-error');

            var _alertAnimation = _errorCompartiment.dataset.animation;

            _errorCompartiment.classList.remove('alert--is-show', _alertAnimation);

            return true;
          }
        });
      },
      verifyEmail: function verifyEmail() {
        $('form').removeData('validator');
        $('form').removeData('unobtrusiveValidation');
        var email = document.getElementById('login').value;
        var pessoa = this.get('pessoa');
        pessoa.set('email', email);
        var inputContainer = document.getElementById('login').closest('.form-group__input-container');
        var input = document.getElementById('login'); // Pega alerta

        var errorCompartiment = document.getElementById('login-error'); // Pega animação do alerta

        var alertAnimation = errorCompartiment.dataset.animation; // Pega container da mensagem a ser escrita

        var msg = errorCompartiment.querySelector('[class*="__msg"]');
        pessoa.verifyEmail({
          login: pessoa.get('email'),
          instituicaoId: this.get('escola').get('id')
        }).then(function (response) {
          errorCompartiment.classList.remove('alert--is-show', alertAnimation);
          inputContainer.classList.add('form-group__input-container--is-validated');
        }).catch(function (error) {
          if (error.errors) {
            inputContainer.classList.remove('form-group__input-container--is-validated');

            if (input) {
              input.focus();
            } // Antiga mensagem de erro
            // document.getElementById('login-error').innerHTML = error.errors[0].title;
            // Pega a identificação do erro


            var errorStatus = error.errors[0].status; // Define mensagem de erro, caso seja o erro XYZ

            var errorMsg;

            if (errorStatus === "400") {
              switch (true) {
                case email.length == 0:
                  errorMsg = 'Por favor, insira um nome de usuário';
                  break;

                case email.length > 0:
                  errorMsg = 'O nome de usuário informado já existe, por favor, escolha outro.';
                  break;
              }
            } else if (errorStatus === "500") {
              errorMsg = 'Ocorreu um erro no sistema, mas não se preocupe! Nossos desenvolvedores já foram alertados.';
            } else {
              errorMsg = 'Ops! Parece que não conseguimos conexão com nossos servidores. Por favor, tente novamente em instantes.';
            } // Injeta mensagem de erro.


            msg.innerHTML = '<strong>' + errorMsg + '</strong>'; // Confere se o elemento já está aparecendo

            if (!errorCompartiment.classList.contains('alert--is-show')) {
              // Adiciona duas classes: uma para o alerta aparecer e outra com a animação definida no html, por meio de data-SBRUBLES
              errorCompartiment.classList.add('alert--is-show', alertAnimation);
            }
          } else {
            // Situação não tratada ainda
            document.getElementById('login-error').innerHTML = '';
          }
        });
      },
      // replaceCPF() {
      //   let target = event.target;
      //   let cpf = target.value;
      //   var regex = /^[0-9.\t]$/;
      //   var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
      //   var code = event.which ? event.which : event.keyCode;
      //   if (!regex.test(key) && code !== 37 && code !== 39 && code !== 8 && code !== 9 && code !== 116) {
      //     let errorMsg = this.set('errorMsgCPF', 'Apenas números são permitidos');
      //     // Pega alerta
      //     const errorCompartiment = document.getElementById('cpf-error');
      //     // Pega animação do alerta
      //     const alertAnimation = errorCompartiment.dataset.animation;
      //     // Pega container da mensagem a ser escrita
      //     const msg = errorCompartiment.querySelector('[class*="__msg"]');
      //     msg.innerHTML = '<strong>' + errorMsg + '</strong>';
      //     // Alerta
      //     errorCompartiment.classList.add('alert--is-show', alertAnimation);
      //     event.preventDefault();
      //     return false;
      //   }
      //   cpf = cpf.replace(/\D/g, "")
      //   cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
      //   cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
      //   cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
      //   this.set('cpf', cpf);
      // },
      // verifyCPF() {
      //   let target = event.target;
      //   let formGroup = target.parentElement;
      //   // Pega alerta
      //   const errorCompartiment = document.getElementById('cpf-error');
      //   // Pega animação do alerta
      //   const alertAnimation = errorCompartiment.dataset.animation;
      //   // Pega container da mensagem a ser escrita
      //   const msg = errorCompartiment.querySelector('[class*="__msg"]');
      //   if (target.value.length < 1 && target.disabled == false) {
      //     let errorMsg = this.set('errorMsgCPF', 'O CPF não pode ficar vazio');
      //     msg.innerHTML = '<strong>' + errorMsg + '</strong>';
      //     // alerta
      //     formGroup.classList.remove('form-group__input-container--is-validated');
      //     errorCompartiment.classList.add('alert--is-show', alertAnimation);
      //     target.focus();
      //   } else if (!this.ValidaCPFValido(target.value) && target.disabled == false) {
      //     let errorMsg = this.set('errorMsgCPF', 'CPF Inválido');
      //     msg.innerHTML = '<strong>' + errorMsg + '</strong>';
      //     // alerta
      //     errorCompartiment.classList.add('alert--is-show', alertAnimation);
      //     formGroup.classList.remove('form-group__input-container--is-validated');
      //     target.focus();
      //   } else {
      //     errorCompartiment.classList.remove('alert--is-show', alertAnimation)
      //     formGroup.classList.add('form-group__input-container--is-validated');
      //   }
      // },
      verifyPassword: function verifyPassword() {
        var p1 = document.getElementById('senha').value;
        var p2 = document.getElementById('senha2').value;
        var passAlert = document.getElementById('pass-error');
        var alertAnimation = passAlert.dataset.animation;
        var msg = passAlert.querySelector('[class*="__msg"]');
        var errorMsg = 'As senhas digitadas ainda não são iguais.';
        var inputsPass = document.querySelectorAll(".j-password");

        if (p1.length > 0 && p2.length > 0 && p1 === p2) {
          document.getElementById('submit').setAttribute('dataDisabled', 'false');
          passAlert.classList.remove('alert--is-show');
          inputsPass.forEach(function (input) {
            input.classList.add('form-group__input-container--is-validated');
          });
        } else if (p1.length > 0 || p2.length > 0) {
          document.getElementById('submit').setAttribute('dataDisabled', 'true'); //document.getElementById('submit').classList.add("btn--disabled");

          inputsPass.forEach(function (input) {
            input.classList.remove('form-group__input-container--is-validated');
          });
          passAlert.classList.add('alert--is-show', alertAnimation);
          msg.innerHTML = '<strong>' + errorMsg + '</strong>';
        }
      },
      createUser: function createUser() {
        this.verifyPasswordLength();

        if (this.verifyPasswordLength()) {
          var button = document.getElementById('submit');
          button.innerHTML = "Aguarde...";
          var password = document.getElementById('senha').value;
          var login = document.getElementById('login').value;
          var sistemas = this.get('store').peekAll('sistema');
          var sistema;
          sistemas.forEach(function (s) {
            if (s.get('idx') == 1) {
              sistema = s;
            }
          });
          var pessoa = this.get('pessoa');
          var that = this;
          pessoa.autoRegister({
            login: login,
            password: password,
            instituicaoId: this.get('escola').get('id'),
            sistemaId: sistema.get('id'),
            role: this.get('model').get('typeCadastro'),
            shouldReviewProfile: true,
            name: login,
            codigoCadastro: this.get("model").get('codigo')
          }).catch(function (error) {
            // Se existe um erro qualificado
            if (error.errors) {
              button.innerHTML = 'Avançar'; // Pega alerta

              var errorCompartiment = document.getElementById('codigo-error'); // Pega animação do alerta

              var alertAnimation = errorCompartiment.dataset.animation; // Pega container da mensagem a ser escrita

              var msg = errorCompartiment.querySelector('[class*="__msg"]'); // Pega a identificação do erro

              var errorStatus = error.errors[0].status; // Define mensagem de erro, caso seja o erro XYZ

              var errorMsg = "Ops! Tivemos um problema para registrar seu usuário. Tente novamente em instantes."; // Injeta mensagem de erro.

              msg.innerHTML = '<strong>' + errorMsg + '</strong>'; // Confere se o elemento já está aparecendo

              if (!errorCompartiment.classList.contains('alert--is-show')) {
                // Adiciona duas classes: uma para o alerta aparecer e outra com a animação definida no html, por meio de data-SBRUBLES
                errorCompartiment.classList.add('alert--is-show', alertAnimation);
              }
            } else {
              that.get('session').authenticate('authenticator:authold', login, password, 1).then(function () {}).catch(function (reason) {});
            }
          });
        }

        ;
      },
      preventPaste: function preventPaste() {
        var regex = new RegExp("^[a-zA-Z0-9@.-_]+$");
        var input = document.getElementById('login');
        var errorMsg = 'Espaço e caracteres especiais não são permitidos';

        if (!regex.test(input.value) && input.value.length > 0) {
          input.value = '';
          var inputContainer = document.getElementById('login').closest('.form-group__input-container');
          inputContainer.classList.remove('form-group__input-container--is-validated'); // Pega alerta

          var errorCompartiment = document.getElementById('login-error'); // Pega animação do alerta

          var alertAnimation = errorCompartiment.dataset.animation; // Pega container da mensagem a ser escrita

          var msg = errorCompartiment.querySelector('[class*="__msg"]'); // Injeta mensagem de erro.

          msg.innerHTML = '<strong>' + errorMsg + '</strong>'; // Confere se o elemento já está aparecendo

          if (!errorCompartiment.classList.contains('alert--is-show')) {
            // Adiciona duas classes: uma para o alerta aparecer e outra com a animação definida no html, por meio de data-SBRUBLES
            errorCompartiment.classList.add('alert--is-show', alertAnimation);
          }
        } else {
          this.send('verifyEmail');
        }
      } // toggleCheckbox() {
      //   let target = event.target;
      //   let closestInput = target.parentNode.nextElementSibling.firstChild.nextElementSibling
      //   let identCheckboxes = document.querySelectorAll('.j-validate-ident');
      //   let uncheckeds = 0;
      //   let fieldsetError = document.getElementById('error-fieldset-ident');
      //   let inputError = closestInput.parentElement.nextElementSibling;
      //   closestInput.value = '';
      //   inputError.classList.remove('alert--is-show');
      //   fieldsetError.classList.remove('fieldset__error--is-show');
      //   identCheckboxes.forEach(c => {
      //     if (!c.checked) {
      //       uncheckeds++
      //     }
      //   })
      //   if (uncheckeds == 2) {
      //     this.set('fieldset1Error', 'Pelo menos uma das formas de identificação precisa ser preenchida');
      //     fieldsetError.classList.add('fieldset__error--is-show');
      //     target.checked = true;
      //     return;
      //   }
      //   if (target.checked) {
      //     closestInput.removeAttribute('disabled');
      //     closestInput.style.opacity = 1;
      //     closestInput.classList.remove('fieldset__field--is-disabled');
      //   } else {
      //     closestInput.disabled = true;
      //     closestInput.style.opacity = 0.3;
      //     closestInput.classList.add('fieldset__field--is-disabled');
      //   }
      // }

    }
  });

  _exports.default = _default;
});