define("semente-web-app/routes/autoregister/form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    model: function model(id) {
      return this.get('store').findRecord('codigo-cadastro', id.codigo_id, {
        include: 'instituicao.plataforma-anos, instituicao.plataforma-turmas.plataforma-ano, instituicao.sistemas',
        reload: true
      });
    }
  });

  _exports.default = _default;
});