define("semente-web-app/models/plataforma-turma", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    numAlunos: _emberData.default.attr(),
    deleted: _emberData.default.attr(),
    pessoas: _emberData.default.hasMany('pessoa', {
      async: true
    }),
    aplicacoes: _emberData.default.hasMany('aplicacao-plataforma-aula', {
      async: true
    }),
    plataformaAno: _emberData.default.belongsTo('plataforma-ano', {
      async: true
    }),
    instituicao: _emberData.default.belongsTo('instituicao', {
      async: true
    })
  });

  _exports.default = _default;
});