define("semente-web-app/models/publico", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    nameFormat: Ember.computed('name', function () {
      if (this.get('name') == 'aluno') return 'Alunos';else if (this.get('name') == 'responsavel') return 'Responsáveis';else if (this.get('name') == 'instrutor') return 'Professores';
    }),
    nameIcon: Ember.computed('name', function () {
      if (this.get('name') == 'aluno') return 'alunos';else if (this.get('name') == 'responsavel') return 'responsaveis';else if (this.get('name') == 'instrutor') return 'professores';
    }),
    descricao: _emberData.default.attr(),
    conteudo: _emberData.default.hasMany('plataforma-conteudo', {
      async: true
    })
  });

  _exports.default = _default;
});