define("semente-web-app/models/acompanhamento-atividade", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    percentComplete: _emberData.default.attr(),
    conteudosCompletos: _emberData.default.attr(),
    atividade: _emberData.default.belongsTo('atividade', {
      async: true
    }),
    pessoa: _emberData.default.belongsTo('pessoa', {
      async: true
    }),
    percentFixed: Ember.computed('percentComplete', function () {
      var percentComplete = this.get('percentComplete');

      if (percentComplete > 100) {
        percentComplete = 100;
      }

      return percentComplete.toFixed(0);
    })
  });

  _exports.default = _default;
});