define("semente-web-app/models/comp", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    idx: _emberData.default.attr(),
    tableOrder: _emberData.default.attr(),
    sigla: _emberData.default.attr(),
    dominio: _emberData.default.belongsTo('dominio', {
      async: true
    }),
    quizesComp: _emberData.default.hasMany('quiz-comp', {
      async: true
    }),
    aulas: _emberData.default.hasMany('aula', {
      async: true
    }),
    atividades: _emberData.default.hasMany('atividade', {
      async: true
    }) //normativas: DS.hasMany('normativa', { async: true }),

  });

  _exports.default = _default;
});