define("semente-web-app/models/avaliacao", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    nota: _emberData.default.attr(),
    dataavaliacao: _emberData.default.attr(),
    pessoa: _emberData.default.belongsTo('pessoa', {
      async: true
    }),
    aula: _emberData.default.belongsTo('aula', {
      async: true
    })
  });

  _exports.default = _default;
});