define("semente-web-app/models/dominio", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var dominioFormat = ['management', 'engagement', 'kindness', 'resilience', 'openness'];

  var _default = _emberData.default.Model.extend({
    idx: _emberData.default.attr(),
    dominioFormatIdx: Ember.computed('idx', function () {
      return dominioFormat[this.get('idx') - 1];
    }),
    name: _emberData.default.attr(),
    comps: _emberData.default.hasMany('comp', {
      async: true
    })
  });

  _exports.default = _default;
});