define("semente-web-app/models/conteudo-pessoa", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    pessoa: _emberData.default.belongsTo('pessoa', {
      async: true
    }),
    plataformaConteudo: _emberData.default.belongsTo('plataforma-conteudo', {
      async: true
    }),
    createdAt: _emberData.default.attr(),
    lastUpdate: _emberData.default.attr(),
    completou: _emberData.default.attr()
  });

  _exports.default = _default;
});