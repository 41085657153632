define("semente-web-app/models/turma", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    numalunos: _emberData.default.attr(),
    modulo: _emberData.default.belongsTo('modulo', {
      async: true
    }),
    instituicao: _emberData.default.belongsTo('instituicao', {
      async: true
    }),
    pessoas: _emberData.default.hasMany('pessoa', {
      async: true
    }),
    acompanhamentosatividades: _emberData.default.hasMany('acompanhamento-atividade-turma', {
      async: true
    }),
    anotacoes: _emberData.default.hasMany('anotacao', {
      async: true
    })
  });

  _exports.default = _default;
});