define("semente-web-app/models/secao", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    idx: _emberData.default.attr(),
    nome: _emberData.default.attr(),
    coverImage: _emberData.default.attr(),
    ultimaObrigatoria: _emberData.default.attr(),
    comTransicao: _emberData.default.attr(),
    naoContaProgressoAtividade: _emberData.default.attr(),
    conteudos: _emberData.default.hasMany('conteudo', {
      async: true
    }),
    atividade: _emberData.default.belongsTo('atividade', {
      async: true
    }),
    backgroundImage: Ember.computed('coverImage', function () {
      return new Ember.String.htmlSafe("background-image: url('" + this.get('coverImage') + "');");
    })
  });

  _exports.default = _default;
});