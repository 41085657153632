define("semente-web-app/models/atividade", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    idx: _emberData.default.attr(),
    description: _emberData.default.attr(),
    timesection: _emberData.default.attr(),
    dia: _emberData.default.attr(),
    liberada: _emberData.default.attr(),
    abertura: _emberData.default.attr(),
    atividade: _emberData.default.attr(),
    video: _emberData.default.attr(),
    quiz: _emberData.default.attr(),
    quizdata: _emberData.default.attr(),
    teoria: _emberData.default.attr(),
    coverImage: _emberData.default.attr(),
    instrutor: _emberData.default.hasMany('pessoa', {
      async: true
    }),
    secoes: _emberData.default.hasMany('secao', {
      async: true
    }),
    modulo: _emberData.default.belongsTo('modulo', {
      async: true
    }),
    comps: _emberData.default.hasMany('comp', {
      async: true
    }),
    acompanhamentosAtividadeInstituicao: _emberData.default.hasMany('acompanhamento-atividade-instituicao', {
      async: true
    }),
    backgroundImage: Ember.computed('coverImage', function () {
      return new Ember.String.htmlSafe("background-image: url('" + this.get('coverImage') + "');");
    }),
    aula: _emberData.default.belongsTo('aula', {
      async: true
    })
  });

  _exports.default = _default;
});