define("semente-web-app/models/acompanhamento-pessoa-plataforma", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    pessoa: _emberData.default.belongsTo('pessoa', {
      async: true
    }),
    conteudoPessoa: _emberData.default.belongsTo('conteudo-pessoa', {
      async: true
    }),
    nrVideosPreparacao: _emberData.default.attr(),
    nrVideosAlunos: _emberData.default.attr(),
    nrTarefasAlunos: _emberData.default.attr(),
    nrAplicacaoPlataformaAulas: _emberData.default.attr(),
    acessos: _emberData.default.attr(),
    ultimoacesso: _emberData.default.attr(),
    role: _emberData.default.attr(),
    name: _emberData.default.attr(),
    userName: _emberData.default.attr(),
    lastAccess: Ember.computed('ultimoacesso', function () {
      return moment(this.get('ultimoacesso'), 'X').format('DD/MM/YYYY');
    }),
    nrConteudoVideoAluno: _emberData.default.attr(),
    nrConteudoVideoProfessor: _emberData.default.attr(),
    nrConteudoVideoOutro: _emberData.default.attr(),
    nrConteudoVideoAlunoTotal: _emberData.default.attr(),
    nrConteudoVideoProfessorTotal: _emberData.default.attr(),
    nrConteudoVideoOutroTotal: _emberData.default.attr(),
    nrConteudoDocumentoAluno: _emberData.default.attr(),
    nrConteudoDocumentoProfessor: _emberData.default.attr(),
    nrConteudoDocumentoOutro: _emberData.default.attr(),
    nrConteudoDocumentoAlunoTotal: _emberData.default.attr(),
    nrConteudoDocumentoProfessorTotal: _emberData.default.attr(),
    nrConteudoDocumentoOutroTotal: _emberData.default.attr()
  });

  _exports.default = _default;
});