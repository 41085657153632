define("semente-web-app/models/calendario", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    nome: _emberData.default.attr(),
    ano: _emberData.default.attr(),
    americano: _emberData.default.attr(),
    instituicaos: _emberData.default.hasMany('instituicao', {
      async: true
    }),
    plataformaConteudos: _emberData.default.hasMany('plataforma-conteudo', {
      async: true
    }),
    atividades: _emberData.default.hasMany('atividade', {
      async: true
    }),
    aulas: _emberData.default.hasMany('aulas', {
      async: true
    }),
    descricao: _emberData.default.attr() // plataformaAnoModulos: DS.hasMany('plataforma-conteudo', { async: true }),

  });

  _exports.default = _default;
});