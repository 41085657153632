define("semente-web-app/authenticators/auth", ["exports", "ember-simple-auth-token/authenticators/jwt", "fetch", "semente-web-app/config/environment"], function (_exports, _jwt, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var assign = Ember.assign || Ember.merge;

  var _default = _jwt.default.extend({
    makeRequest: function makeRequest(url, credentials, headers) {
      var _this = this;

      var content_data = 'UserName=' + credentials.username + '&password=' + credentials.password + '&grant_type=password&KeepLogged=' + credentials.remember;
      return Ember.$.ajax({
        url: url,
        method: 'POST',
        // data: JSON.stringify(data),
        data: content_data,
        dataType: 'json',
        contentType: 'application/json',
        headers: this.headers,
        beforeSend: function beforeSend(xhr, settings) {
          if (_this.headers['Accept'] === null || _this.headers['Accept'] === undefined) {
            xhr.setRequestHeader('Accept', settings.accepts.json);
          }

          if (headers) {
            Object.keys(headers).forEach(function (key) {
              xhr.setRequestHeader(key, headers[key]);
            });
          }
        }
      });
    },
    handleAuthResponse: function handleAuthResponse(response) {
      var token = Ember.get(response, 'access_token');

      if (Ember.isEmpty(token)) {
        throw new Error('Token is empty. Please check your backend response.');
      }

      var tokenData = this.getTokenData(token); // const user_role = tokenData.role.toLowerCase();
      // localStorage.setItem('user_role', user_role);

      var expiresAt = Ember.get(tokenData, this.tokenExpireName);
      var tokenExpireData = {};
      tokenExpireData[this.tokenExpireName] = expiresAt;
      this.scheduleAccessTokenRefresh(expiresAt, token);
      return assign(this.getResponseData(response), tokenExpireData);
    },
    authenticate: function authenticate(credentials, headers) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this2.makeRequest(_this2.serverTokenEndpoint, credentials, headers).then(function (response) {
          Ember.run(function () {
            try {
              var sessionData = _this2.handleAuthResponse(response);

              resolve(sessionData);
            } catch (error) {
              reject(error);
            }
          });
        }, function (xhr) {
          Ember.run(function () {
            reject(xhr.responseJSON || xhr.responseText);
          });
        });
      });
    }
  });

  _exports.default = _default;
});