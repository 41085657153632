define("semente-web-app/models/video", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    videoId: _emberData.default.attr(),
    conteudo: _emberData.default.belongsTo('conteudo', {
      async: true
    }),
    estadosVideo: _emberData.default.hasMany('estado-video', {
      async: true
    })
  });

  _exports.default = _default;
});