define("semente-web-app/controllers/autoregister", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    inputfocus: Ember.run.schedule('afterRender', function () {
      var input = document.getElementById("codigo-escola");

      if (input) {
        input.focus();
      }
    })
  });

  _exports.default = _default;
});