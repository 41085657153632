define("semente-web-app/adapters/application", ["exports", "ember-data", "ember-inflector", "semente-web-app/config/environment"], function (_exports, _emberData, _emberInflector, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import DataAdapterMixin from 'ember-simple-auth/mixins/data-adapter-mixin';
  var inflector = _emberInflector.default.inflector;
  inflector.irregular('instituicao', 'instituicoes');
  inflector.irregular('secao', 'secoes');
  inflector.irregular('quiz', 'quizes');
  inflector.irregular('resposta', 'respostas');
  inflector.irregular('questao', 'questoes');
  inflector.irregular('material', 'materiais'); // inflector.uncountable('acompanhamento-curso-instituicao');

  inflector.uncountable('acompanhamento-atividade-instituicao');
  inflector.uncountable('acompanhamento-ativitade-turma');

  var _default = _emberData.default.JSONAPIAdapter.extend({
    envnmt: _environment.default.APP,
    session: Ember.inject.service('session'),
    host: Ember.computed('envnmt', function () {
      //this will define if the server endpoint for the API calls are relative or absolute. Dev - absolute API endpoint; Prod - relative
      return this.get('envnmt.host');
    }),
    namespace: Ember.computed('envnmt', function () {
      return this.get('envnmt.namespace');
    }),
    headers: Ember.computed(function () {
      var header = localStorage.getItem('person_logged');
      var header_person;

      if (header) {
        header = JSON.parse(header);
        header_person = header.id;
      } else {
        header_person = "";
      }

      var sessionData = this.get('session.data');
      var tok = sessionData.authenticated.access_token;
      var temp = 'Bearer ';
      var userToken = temp.concat(tok);
      return {
        'Content-Type': 'application/json',
        'pessoaid': header_person,
        'Authorization': userToken
      };
    }).volatile()
  });

  _exports.default = _default;
});