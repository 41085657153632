define("semente-web-app/models/acompanhamento-curso-instituicao", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    matriculas: _emberData.default.attr(),
    naoiniciados: _emberData.default.attr(),
    completaram: _emberData.default.attr(),
    instituicao: _emberData.default.belongsTo('instituicao', {
      async: true
    }),
    modulo: _emberData.default.belongsTo('modulo', {
      async: true
    }),
    completaramPerc: Ember.computed('matriculas', 'completaram', function () {
      var matriculas = this.get('matriculas');
      var completaram = this.get('completaram');

      if (typeof matriculas != 'undefined' && typeof completaram != 'undefined') {
        if (matriculas > 0) {
          var result = 100 * completaram / matriculas;
          return result.toFixed(1);
        } else return '0';
      } else return '0';
    })
  });

  _exports.default = _default;
});