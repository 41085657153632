define("semente-web-app/models/resposta", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    timestamp: _emberData.default.attr(),
    alternativa: _emberData.default.belongsTo('alternativa', {
      async: true
    }),
    pessoa: _emberData.default.belongsTo('pessoa', {
      async: true
    })
  });

  _exports.default = _default;
});