define("semente-web-app/models/marketing", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    titulo: _emberData.default.attr(),
    descricao: _emberData.default.attr(),
    capa: _emberData.default.attr(),
    capaName: _emberData.default.attr(),
    area: _emberData.default.belongsTo('area-marketing', {
      async: true
    }),
    pasta: _emberData.default.belongsTo('pasta-marketing', {
      async: true
    }),
    arquivos: _emberData.default.hasMany('arquivo', {
      async: true
    }),
    dataAtualizacao: _emberData.default.attr("date"),
    dataAtualizacaoFormat: Ember.computed('dataAtualizacao', function () {
      var dataformatada = moment(this.get('dataAtualizacao'), 'DD/MM/YYYY hh:mm:ss').format('DD/MM/YYYY');
      return dataformatada;
    }),
    deleted: _emberData.default.attr()
  });

  _exports.default = _default;
});