define("semente-web-app/models/aplicacao-plataforma-aula", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  moment.updateLocale('en', {
    monthsShort: ["jan", "fev", "mar", "abr", "maio", "jun", "jul", "ago", "set", "out", "nov", "dez"]
  });

  var _default = _emberData.default.Model.extend({
    dataAplicacao: _emberData.default.attr(),
    aplicado: _emberData.default.attr(),
    dataAplicacaoFormat: Ember.computed('dataAplicacao', function () {
      var dia = moment(this.get('dataAplicacao'), 'DD/MM/YYYY hh:mm:ss').format('DD');
      var mes = moment(this.get('dataAplicacao'), 'DD/MM/YYYY hh:mm:ss').format('MMMM');
      var dataformatada = dia + ' de ' + mes;
      return dataformatada;
    }),
    dataAplicacaoFormatShort: Ember.computed('dataAplicacao', function () {
      var dia = moment(this.get('dataAplicacao'), 'DD/MM/YYYY hh:mm:ss').format('DD');
      var mes = moment(this.get('dataAplicacao'), 'DD/MM/YYYY hh:mm:ss').format('MMM');
      var dataformatada = dia + '/' + mes;
      return dataformatada;
    }),
    pessoa: _emberData.default.belongsTo('pessoa', {
      async: true
    }),
    pessoaId: _emberData.default.attr(),
    turma: _emberData.default.belongsTo('plataforma-turma', {
      async: true
    }),
    aula: _emberData.default.belongsTo('aula', {
      async: true
    })
  });

  _exports.default = _default;
});