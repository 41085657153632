define("semente-web-app/models/plataforma-conteudo", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    titulo: _emberData.default.attr(),
    filename: _emberData.default.attr(),
    idx: _emberData.default.attr(),
    tipo: _emberData.default.attr(),
    videoUrl: _emberData.default.attr(),
    arquivoUrl: _emberData.default.attr(),
    path: _emberData.default.attr(),
    thumbnail: _emberData.default.attr(),
    thumbnailname: _emberData.default.attr(),
    aulas: _emberData.default.hasMany('aula', {
      async: true
    }),
    agrupamento: _emberData.default.belongsTo('agrupamento', {
      async: true
    }),
    tema: _emberData.default.belongsTo('tema', {
      async: true
    }),
    publicos: _emberData.default.hasMany('publico', {
      async: true
    }),
    situacao: _emberData.default.attr('boolean'),
    calendarios: _emberData.default.hasMany('calendario', {
      async: true
    }),
    dataCriacao: _emberData.default.attr("date"),
    descricao: _emberData.default.attr(),
    indicacao: _emberData.default.attr(),
    indicacaoUrl: _emberData.default.attr(),
    indicacaoDescricao: _emberData.default.attr(),
    plataformaAnos: _emberData.default.hasMany('plataforma-ano', {
      async: true
    })
  });

  _exports.default = _default;
});