define("semente-web-app/models/event", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    title: _emberData.default.attr(),
    description: _emberData.default.attr(),
    link: _emberData.default.attr(),
    start: _emberData.default.attr(),
    end: _emberData.default.attr()
  });

  _exports.default = _default;
});