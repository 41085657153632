define("semente-web-app/models/pessoa", ["exports", "ember-data", "moment", "ember-api-actions"], function (_exports, _emberData, _moment, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    role: _emberData.default.attr(),
    function: _emberData.default.attr(),
    email: _emberData.default.attr(),
    cpf: _emberData.default.attr(),
    userName: _emberData.default.attr(),
    emailCadastrado: _emberData.default.attr(),
    matricula: _emberData.default.attr(),
    genero: _emberData.default.attr(),
    nascimento: _emberData.default.attr(),
    nascimentoPlataforma: _emberData.default.attr(),
    dataNascimento: Ember.computed('nascimento', function () {
      return (0, _moment.default)(this.get('nascimento')).format('YYYY');
    }),
    ano: _emberData.default.attr(),
    password: _emberData.default.attr(),
    confirmpassword: _emberData.default.attr(),
    enabled: _emberData.default.attr(),
    ultimoacesso: _emberData.default.attr(),
    lastAccess: Ember.computed('ultimoacesso', function () {
      return (0, _moment.default)(this.get('ultimoacesso'), 'X').format('DD/MM/YYYY');
    }),
    nracessos: _emberData.default.attr(),
    nrVideosPreparacao: _emberData.default.attr(),
    nrVideosAlunos: _emberData.default.attr(),
    nrAplicacaoPlataformaAulas: _emberData.default.attr(),
    emailsent: _emberData.default.attr(),
    progressototal: _emberData.default.attr(),
    uriAvatar: _emberData.default.attr(),
    numeroCursos: _emberData.default.attr(),
    cursosConcluidos: _emberData.default.attr(),
    shouldReviewProfile: _emberData.default.attr(),
    isAplicador: _emberData.default.attr(),
    isAlsoResponsible: _emberData.default.attr(),
    telefone: _emberData.default.attr(),
    loggedWithDependenteUsername: _emberData.default.attr(),
    acessoS: _emberData.default.attr(),
    acessoMedio: _emberData.default.attr(),
    acessoFp: _emberData.default.attr(),
    area: _emberData.default.belongsTo('area', {
      async: true
    }),
    turmas: _emberData.default.hasMany('turma', {
      async: true
    }),
    sistema: _emberData.default.belongsTo('sistema', {
      async: true
    }),
    modulos: _emberData.default.hasMany('modulo', {
      async: true
    }),
    matriculas: _emberData.default.hasMany('matricula', {
      async: true
    }),
    conteudoPessoas: _emberData.default.hasMany('conteudo-pessoa', {
      async: true
    }),
    instituicao: _emberData.default.belongsTo('instituicao', {
      async: true
    }),
    alternativas: _emberData.default.hasMany('alternativa', {
      async: true
    }),
    respostas: _emberData.default.hasMany('resposta', {
      async: true
    }),
    aplicacaoPlataformaAulas: _emberData.default.hasMany('aplicacao-plataforma-aula', {
      async: true
    }),
    plataformaAnos: _emberData.default.hasMany('plataforma-ano', {
      async: true
    }),
    plataformaTurmas: _emberData.default.hasMany('plataforma-turma', {
      async: true
    }),
    estadoVideos: _emberData.default.hasMany('estado-video', {
      async: true
    }),
    estadoQuestoes: _emberData.default.hasMany('estado-questao', {
      async: true
    }),
    estadoVideosAlternativas: _emberData.default.hasMany('estado-video-alternativa', {
      async: true
    }),
    leituras: _emberData.default.hasMany('leitura', {
      async: true
    }),
    progressoAtividades: _emberData.default.hasMany('acompanhamento-atividade', {
      async: true
    }),
    responsaveis: _emberData.default.hasMany('pessoa', {
      async: true,
      inverse: null
    }),
    dependentes: _emberData.default.hasMany('pessoa', {
      async: true,
      inverse: null
    }),
    // matriculas: DS.hasMany('matricula', {async: true}),
    dataVisualizacaoBiblioteca: _emberData.default.attr(),
    tarefas: _emberData.default.hasMany('tarefa', {
      async: true
    }),
    avaliacoes: _emberData.default.hasMany('avaliacao', {
      async: true
    }),
    anotacoes: _emberData.default.hasMany('anotacao', {
      async: true
    }),
    // codigoCadastro: DS.belongsTo('codigo-cadastro', { async: true }),
    codigoCadastro: _emberData.default.attr(),
    maxDependentes: _emberData.default.attr(),
    verifyEmail: (0, _emberApiActions.memberAction)({
      path: 'verifyEmail',
      type: 'get'
    }),
    verifyCpf: (0, _emberApiActions.memberAction)({
      path: 'verifyCpf',
      type: 'get'
    }),
    autoRegister: (0, _emberApiActions.memberAction)({
      path: 'autoRegisterPlataforma',
      type: 'post'
    }),
    termoAceite: _emberData.default.attr(),
    senha: _emberData.default.attr(),
    acessoPlataformaS: _emberData.default.attr(),
    acessoCs: _emberData.default.attr(),
    comprouAp: _emberData.default.attr(),
    nameLower: Ember.computed('name', function () {
      return this.get('name').toLowerCase();
    }),
    ejaAcessoMedio: _emberData.default.attr(),
    isEja: _emberData.default.attr(),
    mustSendWelcomeEmail: _emberData.default.attr(),
    certificados: _emberData.default.hasMany('certificado', {
      async: true
    }),
    pessoaNotifications: _emberData.default.hasMany('pessoa-notification', {
      async: true
    }),
    disciplinaMinistrada: _emberData.default.attr()
  });

  _exports.default = _default;
});