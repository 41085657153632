define("semente-web-app/models/acompanhamento-sistema-plataforma", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    sistema: _emberData.default.belongsTo('sistema', {
      async: true
    }),
    nrAlunos: _emberData.default.attr(),
    nrProfessoresAplicadores: _emberData.default.attr(),
    nrProfessoresNaoAplicadores: _emberData.default.attr(),
    nrProfessores: _emberData.default.attr(),
    nrSecretarias: _emberData.default.attr(),
    nrMarketings: _emberData.default.attr(),
    nrCoordenadores: _emberData.default.attr(),
    nrGestores: _emberData.default.attr()
  });

  _exports.default = _default;
});