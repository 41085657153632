define("semente-web-app/controllers/autoregister/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service('session'),
    transitionToForm: function transitionToForm(e) {
      this.transitionToRoute('autoregister.form', e.get('id'));
    },
    preventDefault: Ember.run.later(function () {}),
    currentRole: '',
    actions: {
      checkForm: function checkForm() {
        $('form').removeData('validator');
        $('form').removeData('unobtrusiveValidation');
        $.validator.unobtrusive.parse('form');
        var that = this;
        var schoolCode = document.getElementById('codigo-escola').value;
        var input = document.getElementById('codigo-escola');
        var button = document.getElementById('inicia-cadastro');
        button.innerHTML = 'Aguarde...';
        var codigo = this.get('store').queryRecord('codigo-cadastro', {
          include: 'instituicao.plataforma-anos, instituicao.plataforma-turmas',
          codigo: schoolCode
        }).then(function (e) {
          // that.transitionToForm(e);
          that.transitionToRoute('autoregister.form', e.get('id'));
        }).catch(function (error) {
          // Se existe um erro qualificado
          if (error.errors && error.errors[0].status != null) {
            button.innerHTML = 'Iniciar cadastro'; // Pega alerta

            var errorCompartiment = document.getElementById('codigo-error'); // Pega animação do alerta

            var alertAnimation = errorCompartiment.dataset.animation; // Pega container da mensagem a ser escrita

            var msg = errorCompartiment.querySelector('[class*="__msg"]'); // Pega a identificação do erro

            var errorStatus = error.errors[0].status; // Foco no input

            if (input) {
              input.focus();
            } // Define mensagem de erro, caso seja o erro XYZ


            var errorMsg;

            if (errorStatus === "400") {
              switch (true) {
                case schoolCode.length == 0:
                  errorMsg = 'Por favor, insira o código fornecido pela escola.';
                  break;

                case schoolCode.length > 0:
                  errorMsg = error.errors[0].title;
                  break;
              }
            } else if (errorStatus === "500") {
              errorMsg = 'Ocorreu um erro no sistema, mas não se preocupe, nossos desenvolvedores já foram alertados.';
            } // Injeta mensagem de erro.


            msg.innerHTML = '<strong>' + errorMsg + '</strong>'; // Confere se o elemento já está aparecendo

            if (!errorCompartiment.classList.contains('alert--is-show')) {
              // Adiciona duas classes: uma para o alerta aparecer e outra com a animação definida no html, por meio de data-SBRUBLES
              errorCompartiment.classList.add('alert--is-show', alertAnimation);
            } // Se não existe um erro qualificado

          } else if (error.errors[0].status == null) {
            var _errorMsg = 'Não conseguimos conexão com nossos servidores. Por favor, tente novamente em instantes.'; // Pega alerta

            var _errorCompartiment = document.getElementById('codigo-error'); // Pega animação do alerta


            var _alertAnimation = _errorCompartiment.dataset.animation; // Pega container da mensagem a ser escrita

            var _msg = _errorCompartiment.querySelector('[class*="__msg"]'); // Injeta mensagem de erro.


            _msg.innerHTML = '<strong>' + _errorMsg + '</strong>';
            button.innerHTML = 'Iniciar cadastro'; // Confere se o elemento já está aparecendo

            if (!_errorCompartiment.classList.contains('alert--is-show')) {
              // Adiciona duas classes: uma para o alerta aparecer e outra com a animação definida no html, por meio de data-SBRUBLES
              _errorCompartiment.classList.add('alert--is-show', _alertAnimation);
            }
          }
        });
      },

      /**
       * Captura o uso do enter em um input
       * @param  {Element} elemento que foi usado para acionar a função
       */
      checkFormEnter: function checkFormEnter(e) {
        if (e.key === 'Enter') {
          // Evita do form ser enviado
          e.preventDefault(); // Chama outro método dentro de "actions"

          this.send(e.target.dataset.function);
        }
      },
      identifyRole: function identifyRole() {
        var target = event.target;
        var inputedCode = target.value.trim();
        target.value = inputedCode;

        if (inputedCode == '1234' || inputedCode == '1235' || inputedCode == '1236') {
          this.set('currentRole', 'teste semente');
        } else if (inputedCode.toLowerCase().includes("-al-")) {
          this.set('currentRole', 'aluno');
        } else if (inputedCode.toLowerCase().includes("-pr-")) {
          this.set('currentRole', 'professor');
        } else if (inputedCode.toLowerCase().includes("-re-")) {
          this.set('currentRole', 'responsável');
        } else {
          this.set('currentRole', '');
        }
      }
    }
  });

  _exports.default = _default;
});